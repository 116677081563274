import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageLayout from '../../components/Layout/PageLayout';
import QueryString from 'query-string';
import { JumioSdkProps } from './types';
import { DATA_CENTER, JUMIO_EVENTS } from './helper';
import useApi from './hooks/useApi';
import Api from '../../helpers/Api';
import ReviewPage from './review';
// Declare the 'jumio-sdk' element in the JSX.IntrinsicElements interface

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'jumio-sdk': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & { dc: string; token?: string };
    }
  }
}

const JumioSdk = ({ sdkToken, onSuccess, onFailure }: JumioSdkProps) => {
  useEffect(() => {
    const script = document.createElement('script');
    // Gatsby serves files from `static`  jumio js is the javascript code
    script.src = '/jumio.js';
    script.type = 'module';
    script.onload = () => {
      if (sdkToken) {
        const jumioSDK = document.querySelector('jumio-sdk');
        if (jumioSDK) {
          jumioSDK.addEventListener(JUMIO_EVENTS.SUCCESS, (event) => {
            onSuccess(event?.detail);
          });
          jumioSDK.addEventListener(JUMIO_EVENTS.FAILED, (_) => {
            onFailure();
          });
        }
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [sdkToken]);

  return (
    <jumio-sdk
      dc={DATA_CENTER.SG}
      style={{ width: '60%', height: '80%' }}
      token={sdkToken}
    ></jumio-sdk>
  );
};

const ekyc = () => {
  let search = typeof window !== 'undefined' ? window.location.search : '';
  const { sdkToken, identityKycToken } = QueryString.parse(search) || {};

  // TODO: to handle loading and error
  const { loading, error, data, request } = useApi(Api.retrieveKycData, true);

  // TODO: pass data to review component

  const onSuccess = (event: any) => {
    request(identityKycToken);
  };

  const onFailure = () => {
    // TODO: to handle onFailure
  };

  return (
    <PageLayout>
      <Header />
      {data ? (
        <ReviewPage
          sdkToken={sdkToken}
          identityKycToken={identityKycToken}
          reviewData={data}
        />
      ) : (
        <JumioSdk
          sdkToken={sdkToken}
          onSuccess={onSuccess}
          onFailure={onFailure}
        />
      )}
      <Footer />
    </PageLayout>
  );
};

export default ekyc;
